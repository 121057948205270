.developer_wrapper{
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
    padding: 5%;
}

.developer_wrapper td{
    text-align: center;
    margin: 10px;
    font-size: large;
    /* width: 50%; */
    padding: 10px;
}

.developer_wrapper tr{
    text-align: center;
    margin: 10px;
    padding: 10px;
    align-items: center;
    align-content: center;
}

.developer_wrapper img{
    width: 160px;
    /* height: 250px; */
}