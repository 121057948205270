/* @import url(app.css); */

.circles {
  flex-direction: row;
  margin-top: -15rem;
  justify-content: space-evenly;
  align-items: center;
}

.circle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* gap: 0.1rem; */
  background-color: var(--white);
  width: 200px;
  height: 200px;
  border-radius: 50%;
  align-items: center;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
}

.counter {
  font-size: 3rem;
  font-weight: 600;
  color: var(--darker-blue);
}

.circle-name {
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--light-text-color);
}

@media screen and (max-width: 1000px) {
  .circle {
    width: 200px;
    height: 200px;
  }
  .counter {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 770px) {
  .circle {
    width: 150px;
    height: 150px;
  }
  .counter {
    font-size: 2rem;
  }

  .circle-name {
    font-size: 1rem;
  }
}

@media screen and (max-width: 630px) {
  .circle {
    width: 130px;
    height: 130px;
  }
  .counter {
    font-size: 1.5rem;
  }

  .circle-name {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 550px) {
  .circle {
    width: 100px;
    height: 100px;
  }
  .counter {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 460px) {
  .circle {
    width: 80px;
    height: 80px;
  }
  .counter {
    font-size: 1.3rem;
  }
}
