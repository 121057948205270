/* STICKY NAVIGATION */
.sticky .header {
  z-index: 10;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 8rem;
  padding-top: 0;
  padding-bottom: 0;
  background-color: rgba(255, 255, 255, 0.97);
  z-index: 999;
  box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.03);
}

.sticky .section-hero {
  margin-top: 9.6rem;
}

/**************************/
/* HERO SECTION */
/**************************/

.section-hero {
  background-color: #fdf2e9;
  padding: 4.8rem 0 9.6rem 0;
}

.hero {
  max-width: 130rem;
  margin: 0 auto;
  /* margin-left: 5%; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 9.6rem;
  align-items: center;
}

.hero-description {
  font-size: 2rem;
  line-height: 1.6;
  margin-bottom: 4.8rem;
  text-align: justify;
}

.hero-img {
  width: 100%;
}

.delivered-meals {
  display: flex;
  align-items: center;
  gap: 1.6rem;
  margin-top: 8rem;
}

.delivered-imgs {
  display: flex;
}

.delivered-imgs img {
  height: 4.8rem;
  width: 4.8rem;
  border-radius: 50%;
  margin-right: -1.6rem;
  border: 3px solid #fdf2e9;
}

.delivered-imgs img:last-child {
  margin: 0;
}

.delivered-text {
  font-size: 1.8rem;
  font-weight: 600;
}

.delivered-text span {
  color: #cf711f;
  font-weight: 700;
}


/**************************/
/* FEATURED IN SECTION */
/**************************/

.section-featured {
  padding: 4.8rem 0 3.2rem 0;
}

.heading-featured-in {
  font-size: 1.4rem;
  text-transform: uppercase;
  letter-spacing: 0.75px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 2.4rem;
  color: #888;
}

.logos {
  display: flex;
  justify-content: space-around;
}

.logos img {
  height: 3.2rem;
  filter: brightness(0);
  opacity: 50%;
}

/**************************/
/* HOW IT WORKS SECTION */
/**************************/

.section-how {
  padding: 9.6rem 0;
}

.step-number {
  font-size: 8.6rem;
  font-weight: 600;
  color: #bab9b9;
  margin-bottom: 1.2rem;
}

.step-description {
  font-size: 1.8rem;
  line-height: 1.8;
}

.step-img-box {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
}

.step-img-box::before,
.step-img-box::after {
  content: "";
  display: block;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.step-img-box::before {
  width: 60%;
  /* height: 60%; */

  /* 60% of parent's width */
  padding-bottom: 60%;

  background-color: #fdf2e9;
  z-index: -2;
}

.step-img-box::after {
  width: 45%;
  padding-bottom: 45%;
  background-color: #fae5d3;
  z-index: -1;
}

.step-img {
  width: 35%;
  /* z-index: 10; */
}

/* Home.css */

.home-container {
    /* max-width: 800px; */
    width: auto;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
  }
  
  .welcome-section h1 {
    color: #333;
  }
  
  .policy-section,
  .call-for-articles-section,
  .license-section {
    margin-top: 20px;
  }
  
  .call-for-articles-section ul {
    list-style-type: square;
  }
  
  /* Media queries starts here */
  @media (max-width: 84em) {
    .hero {
      max-width: 120rem;
    }
  
    .heading-primary {
      font-size: 4.4rem;
    }
  }

  @media (max-width: 75em) {
    html {
      /* 9px / 16px  */
      font-size: 56.25%;
    }
  
    .grid {
      column-gap: 4.8rem;
      row-gap: 6.4rem;
    }
  
    .heading-secondary {
      font-size: 3.6rem;
    }
  
    .heading-tertiary {
      font-size: 2.4rem;
    }
  
    .header {
      padding: 0 3.2rem;
    }
  
    .main-nav-list {
      gap: 3.2rem;
    }
  
    .hero {
      gap: 4.8rem;
    }
  
  }
  
/**************************/
/* BELOW 944px (Tablets) */
/**************************/

  @media (max-width: 59em) {
    html {
      /* 8px / 16px = 0.5 = 50% */
      font-size: 50%;
    }
  
    .hero {
      grid-template-columns: 1fr;
      padding: 0 8rem;
      gap: 6.4rem;
    }
  
    .hero-text-box,
    .hero-img-box {
      text-align: center;
    }
  
    .hero-img {
      width: 60%;
    }
  
    .delivered-meals {
      justify-content: center;
      margin-top: 3.2rem;
    }
  
    .logos img {
      height: 2.4rem;
    }
  
    .step-number {
      font-size: 7.4rem;
    }
  
    .meal-content {
      padding: 2.4rem 3.2rem 3.2rem 3.2rem;
    }
  
    .section-testimonials {
      grid-template-columns: 1fr;
    }
  
    .gallery {
      grid-template-columns: repeat(6, 1fr);
    }
  
  
  }