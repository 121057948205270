/* JournalCard.css */

/* Flex container for the list of journals */
.journal-list {
    width:100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
    /* margin: 30px; */
  }
  
  .journal-card {
    display: block;
    width: 60%;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    align-content: center;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    font-size: larger;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 20px;
    transition: transform 0.3s ease-in-out;
    /* flex: 0 0 calc(100% - 40px); At most, four cards per row with 20px margin on each side */
    box-sizing: border-box;
    /* margin-left: 25%; */
    height: auto;
  }
  
  .journal-card:hover {
    transform: scale(1.05);
  }
  
  .journal-card h3 {
    font-size: x-large;
    margin-bottom: 10px;
    color: #333;
    padding: 5px;
  }
  
  .journal-card p {
    margin: 0;
    color: #666;
    padding:5px;
  }
  
  .detail-button {
    display: inline-block;
    margin-top: 15px;
    padding: 10px 15px;
    background-color: #3498db;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
  }
  
  .detail-button:hover {
    background-color: #2a75a2;
  }
  
  /* Responsive styles */

  
  @media (max-width: 992px) {
    .journal-card {
    
      /* margin-left: 20%; */
      width: 60%;
    }
  }
  
  @media (max-width: 768px) {
    .journal-card {
     
      /* margin-left: 10%; */
      width: 80%;
    }
  }
  