body {
    
    margin: 0;
  }
  
  .wrapper {
    flex: 1;
    width: 100%;
  }
  
  .footer {
    padding: 12.8rem 0;
    border-top: 1px solid #eee;
    background-color: #fdf2e9;
    flex-direction: row;
  }
  
  .grid--footer {
    grid-template-columns: 45% 45%;
   
  }
  
  .logo-col {
    padding: 5%;
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-size: 1.6rem;
    line-height: 1.6;
  }
  
  .footer-logo {
    display: block;
    margin-bottom: 3.2rem;
  }
  
  .address-col {
    padding: 5%;
    display: flex;
    flex-direction: column;
  }
 
 
  .copyright {
    font-size: 1.4rem;
    line-height: 1.6;
    color: #767676;
    margin-top: auto;
  }
  
  .footer-heading {
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 4rem;
  }
  
  .contacts {
    font-style: normal;
    font-size: 1.6rem;
    line-height: 1.6;
  }
  
  .address {
    margin-bottom: 2.4rem;
  }
  
  .footer-nav {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }
  
  .footer-link:link,
  .footer-link:visited {
    text-decoration: none;
    font-size: 1.6rem;
    color: #767676;
    transition: all 0.3s;
  }
  
  .footer-link:hover,
  .footer-link:active {
    color: #555;
  }