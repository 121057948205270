.authors-wrapper{
    display: flex;
    flex-wrap: wrap;
    margin-left: 5%;
    margin-top: 5%;
    flex-direction: column;
    width: 90%;
    line-height: 1.5;
  }

  .authors-token{
    background-color: #fdf2e9;
    padding: 2%;
    margin-bottom: 4%;
    width: 90%;
    margin-left: 2.5%;
    font-size: 1.5rem;
  }
                                                       
  .authors-token h1{
    text-align: center;
  }

  .authors-token:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); 
    
  }

  .description{
    padding: 5%;
    width: 90%;
    line-height: 2;
    font-size: 1.8rem;
  }