/* Reset some default styles */
body, h1, h2, h3, p, label, button {
    margin: 0;
    padding: 0;
  }
  
  body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  /* Improved styles for the login form */
  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
    font-size: large;
    background-color: #f0f0f0;
    background-image: linear-gradient(to right bottom, #ffe9d5, #ecba8f);
  }
  
  .login-form {
    /* height: 50%; */
    width: 350px;
    color: #45260a;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fdf2e9;
  }
  
  .form-title {
    font-size: x-large;
    text-align: center;
    font-weight: 700;
    margin-bottom: 20px;
    color: #45260a;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    color: #555;
  }
  
  input {
    width: 100%;
    padding: 10px;
    font-size: large;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .login-button {
    background-color: #3498db;
    color: #fff;
    padding: 10px;
    font-size: large;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .signup-link {
    text-align: center;
    margin-top: 20px;
    color: #555;
  }
  
  .signup-link a {
    color: #3498db;
    text-decoration: none;
    font-weight: bold;
  }
  