.archive-wrapper{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  padding: 2%;

}
.archives{
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 30%;
  justify-content: center;
}
.archive-card {
    width: 150px;
    height: 150px;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  .archiveLink{
    color: black;
    /* font-weight: bold; */
    text-decoration: none;
    font-size: large;
  }

  .archiveLink:hover{
    font-size: x-large;
  }

  .archiveLink:hover{
    transform: scale(1.1) rotate(2deg);
  }

  .archive_issue{
    color: black;
    width: 100%;
    height: 10%;
    text-align: center;
    font-size: large;
    background-color: #fdf2e9;
    cursor: pointer;
  }

  .archive_issue td{
    padding: 2%;

  }

  .archive_issue th{
    background-color: #e67e22;
    padding: 10px;
  }

  .archive_issue:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); 
    
  }

  @media (max-width: 59em) {
    .archive-card {
    height: 100px;
    width: 100px;
    
    }
    .archives{
      width: 80%;
    }
    .archive_issue{
      font-size: small;
    }
  }
  
 